var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tui tuim ui card status-bar", attrs: { id: "status-bar" } },
    [
      _c("div", { staticClass: "content ui grid centered middle" }, [
        _vm.title
          ? _c(
              "div",
              {
                staticClass: "title left floated left aligned four wide column"
              },
              [
                _vm.loading
                  ? _c("t-placeholver", {
                      attrs: { lines: 1, header: true, paragraph: false }
                    })
                  : _c("h2", { domProps: { innerHTML: _vm._s(_vm.title) } })
              ],
              1
            )
          : _c(
              "div",
              {
                staticClass: "title left floated left aligned four wide column"
              },
              [_vm._t("leftContent")],
              2
            ),
        _c(
          "div",
          {
            staticClass: "items right floated right aligned twelve wide column"
          },
          [_vm._t("rightContent")],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }