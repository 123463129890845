<template>
  <div id="status-bar" class="tui tuim ui card status-bar">
    <div class="content ui grid centered middle">
      <div v-if="title" class="title left floated left aligned four wide column">
        <t-placeholver v-if="loading" :lines="1" :header="true" :paragraph="false" />
        <h2 v-else v-html="title" />
      </div>
      <div v-else class="title left floated left aligned four wide column">
        <slot name="leftContent" />
      </div>
      <div class="items right floated right aligned twelve wide column">
        <slot name="rightContent"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TStatusBar',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
